import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../../store";
import React, {useCallback, useContext, useMemo} from "react";
import {DiscunaContext} from "../../../../index";
import {Column, Row} from "../../../../components/motion_mui";
// import {sidebarWidth} from "../../../../constants/constants";
// import {
//     Avatar,
//     Badge,
//     Box,
//     Icon,
//     IconButton,
//     List,
//     ListItem,
//     ListItemIcon,
//     ListItemText,
//     useTheme
// } from "@material-ui/core";
import {
    Avatar,
    Button,
    List,
    ListItem, ListItemButton,
    ListItemIcon,
    ListItemText, Typography,
    useTheme
} from "@mui/material";
// import InboxOutlinedIcon from "@material-ui/icons/InboxOutlined";
// import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
// import {AccountCircleOutlined} from "@material-ui/icons";
// import {upsertNotification, selectAllNotifications} from "../notifications/notificationsSlice";
// import {Notification} from "../notifications/notificationInterfaces";
import {selectAllInboxMessages} from "../inbox/inboxSlice";
import {CommunitySection} from "./communities/communitySection";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import {EnablePushNotificationButton} from "./enablePushNotificationButton";
import {ResizeXRight} from "./ResizeXRight";
import {setSidebarWidth} from "../../dashboardSlice";

export function NavigationBar(props: {
    navTo: (navId: string) => void
}) {

    const location = useLocation()
    const accountIsSelected = useMemo(() => location.pathname.includes("account"), [location])
    const inboxIsSelected = useMemo(() => location.pathname.includes("inbox"), [location])
    const devIsSelected = useMemo(() => location.pathname.includes("dev"), [location])


    const theme = useTheme()

    const auth = useSelector((state: RootState) => state.auth)
    const userSecrets = useSelector((state: RootState) => state.userSecrets.data)
    const isSignedIn = useSelector((state: RootState) => state.auth.isSignedIn)
    const dispatch = useDispatch()

    // const notifications = useSelector((state: RootState) => selectAllNotifications(state))
    const inboxMessages = useSelector((state: RootState) => selectAllInboxMessages(state))
    const numUnreadMessages = useMemo(() => inboxMessages.filter(m => !m.hasRead).length, [inboxMessages])
    // TODO numNewMessages

    const isDemo = useContext(DiscunaContext).isDemo
    const isDesktop = useContext(DiscunaContext).isDesktop

    const userName = auth.isSignedIn && auth.data.type === "email" ? auth.data.name ?? "Member" : "Guest"
    const userNameSplit = userName.split(' ')

    const sidebarWidth = useAppSelector(state => state.dashboard.sidebarWidth)

    const avatarInitials = auth.isSignedIn && auth.data.type === "email" ?
        ((userNameSplit.slice(0, 1)[0]?.charAt(0) ?? '').concat(userNameSplit.slice(1, 2)[0]?.charAt(0) ?? ''))
        : '?'

    return (
        <Column
            sx={{
                backgroundColor: "#F9FCFF",
                minWidth: sidebarWidth + "px",
                maxWidth: sidebarWidth + "px",
                height: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                overflowY: "auto",
                position: "relative",
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
        >
            <Row sx={{px: 2, mt: 4, mb: 2}} mainAxisAlignment={"spaceBetween"}
                 crossAxisAlignment={"center"}>
                <Row mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
                    <Avatar style={{
                        // border: `2px solid ${theme.palette.primary.main}`,
                        // backgroundColor: "none",
                        // background: "none",
                        // color: "black"
                        backgroundColor: theme.palette.primary.main, //deepOrange[500],
                        color: theme.palette.getContrastText(theme.palette.primary.main),
                    }}>
                        {avatarInitials}
                    </Avatar>
                    <Typography sx={{ml: 2, fontWeight: 500}} variant={"body1"}>{userName}</Typography>
                </Row>
            </Row>
            {/*{*/}
            {/*    !window.discunaDesktopAPI && !window.qandaDesktopAPI &&*/}
            {/*    <Button variant={"outlined"} sx={{mx: 2, mb: 1}} onClick={() => {*/}
            {/*        window.open("https://discuna.com/download", "_blank")*/}
            {/*    }}>*/}
            {/*        Discover the desktop app*/}
            {/*        <OpenInNewOutlinedIcon sx={{ml: 1, width: "18px"}}/>*/}
            {/*    </Button>*/}
            {/*}*/}
            <EnablePushNotificationButton sx={{mx: 2, mb: 1}}/>
            <List component="nav" sx={{paddingBottom: "0px"}}>
                <ListItem disabled={isDemo}
                          selected={accountIsSelected}
                          button
                          key={"account"}
                          onClick={() => {
                              // firestoreManager.stopLiveUpdateContaining("URLInfo")
                              // firestoreManager.stopLiveUpdateContaining("PDFInfo")
                              props.navTo("account")
                          }}>
                    <ListItemIcon>
                        {/*<AccountCircleOutlined/>*/}
                        <PermIdentityOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Account"}/>
                </ListItem>
                <ListItem button
                          key={"helpCenter"}
                          onClick={() => {
                              // firestoreManager.stopLiveUpdateContaining("URLInfo")
                              // firestoreManager.stopLiveUpdateContaining("PDFInfo")
                              window.open("https://support.discuna.com/", "_blank")
                          }}>
                    <ListItemIcon>
                        <SupportOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            "Help Center"
                            // <Row mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
                            //     <Typography variant={"body1"}>
                            //         Help Center
                            //     </Typography>
                            //     <OpenInNewOutlinedIcon sx={{ml: 1, height: 20}}/>
                            // </Row>
                        }
                    />
                </ListItem>
                {
                    userSecrets && userSecrets.roles && userSecrets.roles.includes("dev") &&
                    <ListItemButton
                        key={"dev"}
                        selected={devIsSelected}
                        onClick={() => {
                            // firestoreManager.stopLiveUpdateContaining("URLInfo")
                            // firestoreManager.stopLiveUpdateContaining("PDFInfo")
                            props.navTo("dev")
                        }}>
                        <ListItemIcon>
                            {/*<AccountCircleOutlined/>*/}
                            <DeveloperBoardOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={"Dev"}/>
                    </ListItemButton>
                }
                {
                    userSecrets && userSecrets.roles && userSecrets.roles.includes("admin") &&
                    <ListItemButton
                        key={"admin"}
                        onClick={() => {
                            // firestoreManager.stopLiveUpdateContaining("URLInfo")
                            // firestoreManager.stopLiveUpdateContaining("PDFInfo")
                            props.navTo("admin")
                        }}>
                        <ListItemIcon>
                            {/*<AccountCircleOutlined/>*/}
                            <AdminPanelSettingsOutlinedIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={"Admin Panel"}/>
                    </ListItemButton>
                }
                {/*<ListItem disabled={isDemo}*/}
                {/*          selected={inboxIsSelected}*/}
                {/*          button*/}
                {/*          key={"inbox"}*/}
                {/*          onClick={() => props.navTo("inbox")}>*/}
                {/*    <ListItemIcon>*/}
                {/*        <Badge color={"secondary"} badgeContent={numUnreadMessages}>*/}
                {/*            <InboxOutlinedIcon/>*/}
                {/*        </Badge>*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText*/}
                {/*        primary={"Inbox"}/>*/}
                {/*</ListItem>*/}
            </List>
            {
                <CommunitySection navTo={props.navTo}/>
                // <NavLibrary navTo={props.navTo}/>
            }
        </Column>
    )
}
