import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../../../store";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useMemo, useRef, useState} from "react";
// import {sidebarWidth} from "../../../../../constants/constants";
import {Box, CircularProgress} from "@mui/material";
import {Center} from "../../../../../components/motion_mui";
import {selectDiscunaAppById} from "../apps/discunaAppSlice";
import {discunaCoreFrontendApiHost} from "../../../../discunaCoreFrontendCom/discunaCoreFrontendApiHost";
import {discunaAppFrontendApiClient} from "../../../../discunaCoreFrontendCom/discunaAppFrontendApiClient";
import {setQueuedNotification} from "../../inbox/inboxSlice";
import {useChannel} from "./channelUtils";
import {selectChannelById} from "./channelSlice";

export function ChannelContainerWrapper() {

    const auth = useSelector((state: RootState) => state.auth)
    const {communityId, channelId} = useParams<{
        communityId: string,
        channelId: string,
    }>()


    if(!auth.isSignedIn || !channelId || !communityId) return null
    // render
    return (
        <ChannelContainer
            key={channelId}
            communityId={communityId}
            memberId={auth.uid}
            channelId={channelId}
        />
    )
}

function ChannelContainer({communityId, channelId, memberId}: {
    communityId: string,
    channelId: string,
    memberId: string,
}) {

    // fetch channel
    useChannel(communityId, channelId)
    const channel = useSelector((state: RootState) => selectChannelById(state, channelId))
    const app = useSelector((state: RootState) => selectDiscunaAppById(state, channel?.details.appId??""))

    const dispatch = useDispatch()

    // iframe
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const [iframeIsReady, setIframeIsReady] = useState(false)

    // page ready handler
    const queuedNotification = useSelector((state: RootState) => state.inbox.queuedNotification)
    useEffect(() => {
        if (app && iframeRef.current?.contentWindow) {
            return discunaCoreFrontendApiHost.onPageIsReady(() => {
                    setIframeIsReady(true)
                    if (queuedNotification &&
                        queuedNotification.details.channelId === channelId &&
                        iframeRef.current?.contentWindow
                    ) {
                        discunaAppFrontendApiClient.notificationClick(
                            queuedNotification.id,
                            queuedNotification.details.data,
                            app.details.config.pages.origin,
                            iframeRef.current?.contentWindow
                        ).catch((err) => {
                            console.error(err)
                        })
                        dispatch(setQueuedNotification(null))
                    }
                },
                app.details.config.pages.origin,
                iframeRef.current?.contentWindow
            )
        }
    }, [app, channelId, dispatch, queuedNotification])

    // channel name handler
    useEffect(() => {
        if (app && iframeRef.current?.contentWindow && channel) {
            return discunaCoreFrontendApiHost.onGetChannelName(() => {
                    return channel.details.name
                },
                app.details.config.pages.origin,
                iframeRef.current?.contentWindow
            )
        }
    }, [app, channel])

    // member ID handler
    useEffect(() => {
        if (app && iframeRef.current?.contentWindow) {
            return discunaCoreFrontendApiHost.onGetMemberId(() => {
                    return memberId
                },
                app.details.config.pages.origin,
                iframeRef.current?.contentWindow
            )
        }
    }, [app, memberId])


    const sidebarWidth = useAppSelector((state) => state.dashboard.sidebarWidth)

    const location = useLocation()
    const isMobile = useMemo(() => {
        return location.pathname.includes("mobile")
    }, [location])


    if (!app) return null

    // render
    return (
        <Box
            sx={{
                flexGrow: 1,
                minHeight: "100%",
                maxHeight: "100%",
                position: "relative",
                ...(isMobile) && {
                    minWidth: "100%"
                },
                ...(!isMobile) && {
                    maxWidth: `calc(100% - ${sidebarWidth}px)`,
                },
                backgroundColor: "#E5E7E9"
            }}>
            {
                !iframeIsReady && <Center>
                    <CircularProgress size={24}/>
                </Center>
            }
            {
                typeof app.details.config.pages.origin === "string" &&
                <iframe
                    id={`iframe-${channelId}`}
                    ref={iframeRef}
                    title={"create page"}
                    src={`${app.details.config.pages.origin}/discuna/communities/${communityId}/channels/${channelId}`}
                    height={"100%"}
                    width={"100%"}
                    style={{border: "none", display: iframeIsReady ? "inherit" : "none"}}
                />
            }
        </Box>
    )
}

