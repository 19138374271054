import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {useUserSecrets} from "../../userSecrets/userSecretsUtils";
import {useUnreadInboxMessages} from "../features/inbox/inboxUtils";
import {useAppsOnce} from "../features/communities/apps/useAppsOnce";
import {AvatarMotion, Column, Row} from "../../../components/motion_mui";
import {NavigationBar} from "../features/navbar/navigationBar";
import React, {useCallback, useMemo, useState} from "react";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';


// import {IonRippleEffect} from '@ionic/react';

import {
    Avatar,
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Divider,
    IconButton, Menu,
    MenuItem,
    Typography
} from "@mui/material";
import {RootState, useAppSelector} from "../../store";
import {useSelector} from "react-redux";
import {selectAllInboxMessages} from "../features/inbox/inboxSlice";
import {AvatarButton} from "../../../components/avatarButton";
import useWindowDimensions from "../../../hooks/window";
import {lightBlue} from "@mui/material/colors";
import {lightGrey, primaryColor, primaryLightBackground} from "../../../constants/constants";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';

import {motion, AnimatePresence} from "framer-motion";
import {RestrictToRegistered} from "../features/communities/accessWrappers";
import {JoinCommunityModal} from "../features/navbar/communities/contextMenu/joinCommunity";
import {AddCommunityModal} from "../features/navbar/communities/contextMenu/addCommunity";

// import {
//     setupIonicReact,
//     IonApp,
//     IonNav,
//     IonNavLink,
//     IonButton,
//     IonHeader,
//     IonTitle,
//     IonToolbar,
//     IonContent,
//     IonButtons,
//     IonBackButton
// } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
// import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
// import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';
import {LoadingScreen} from "../../../utils/loadingScreen";
import {useBookmarks} from "../features/bookmarks/useBookmarks";
import {CommunityBookmarkLoader} from "../features/bookmarks/communityBookmarkLoader";

// /* Optional CSS utils that can be commented out */
// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

// setupIonicReact()

// export function DashboardMobile() {
//
//     // useGlobalHoverFix()
//
//     // Message handler between discuna and the discuna apps
//     // useDiscunaAppBridge()
//
//     // TODO check for updates and reload page if necessary
//
//     return (
//         <IonApp>
//             <IonNav root={() => <MainPage/>}/>
//         </IonApp>
//     )
// }


export function DashboardMobile() {

    useUserSecrets()
    useUnreadInboxMessages()
    useBookmarks()
    useAppsOnce()

    const location = useLocation()
    const accountIsSelected = useMemo(() => location.pathname.includes("account"), [location])
    const inboxIsSelected = useMemo(() => location.pathname.includes("inbox"), [location])
    const devIsSelected = useMemo(() => location.pathname.includes("dev"), [location])

    const navValue = useMemo(() => {
        return accountIsSelected ? 0 : inboxIsSelected ? 1 : devIsSelected ? 2 : 0
    }, [accountIsSelected, devIsSelected, inboxIsSelected])

    const navigate = useNavigate()
    const navTo = (navId: string) => {
        navigate(`./${navId}`)
    }

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                height: "100%"
            }}
        >
            <Box sx={{
                flex: 1,
                overflow: "auto",
                width: "100%"
            }}>
                <Outlet/>
            </Box>
            <CommunityBookmarkLoader/>
            <DiscunaBottomNav navTo={navTo}/>
        </Column>
    )
}


export function DiscunaBottomNav({navTo}: {
    navTo: (navId: string) => void
}) {

    const userSecrets = useSelector((state: RootState) => state.userSecrets)
    const userSecretsData = userSecrets.data
    const membershipsNotSynced = useMemo(() => new Set(userSecrets.membershipsNotSynced),
        [userSecrets.membershipsNotSynced])

    // context menus
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | EventTarget & HTMLElement>(null);
    const [showMoreActions, setShowMoreActions] = useState(false)
    const [showCommunityList, setShowCommunityList] = useState(false)

    const {communityId} = useParams<{ communityId: string }>()

    // const previews = useMemo(() => {
    //     if (userSecretsData) {
    //         let previews2 = [] as {id: string, name: string}[]
    //         for(const communityId of Object.keys(userSecretsData.details.previews)) {
    //             previews2.push({
    //                 id: communityId,
    //                 name: userSecretsData.details.previews[communityId].name
    //             })
    //         }
    //         previews2.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
    //         return previews2
    //     }
    //     return []
    // }, [userSecretsData])

    const memberships = useMemo(() => {
        if (userSecretsData) {
            let memberships = [] as { id: string, name: string }[]
            for (const communityId of Object.keys(userSecretsData.details.memberships)) {
                memberships.push({
                    id: communityId,
                    name: userSecretsData.details.memberships[communityId].name
                })
            }
            memberships.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
            return memberships
        }
        return []
    }, [userSecretsData])

    const allInboxMessages = useSelector((state: RootState) => selectAllInboxMessages(state))

    // const selectUnreadMessagesWithTargetChannelMemo = useMemo(selectUnreadMessagesWithTargetChannel, [])
    // const unreadMessagesWithTargetChannel = useSelector((state: RootState) => selectUnreadMessagesWithTargetChannelMemo(state))

    const unreadMessageCount = useMemo(() => {
        const unreadMessageCount = {
            "normal": new Map<string, number>(),
            "high": new Map<string, number>(),
        }
        memberships.forEach((membership) => {
            unreadMessageCount.normal.set(membership.id, 0)
            unreadMessageCount.high.set(membership.id, 0)
        })
        allInboxMessages.forEach((inboxMessage) => {
            if (!inboxMessage.hasRead) {
                const priority = inboxMessage.priority
                unreadMessageCount[priority].set(
                    inboxMessage.details.communityId,
                    (unreadMessageCount[priority].get(inboxMessage.details.communityId) ?? 0) + 1
                )
            }
        })
        return unreadMessageCount
    }, [allInboxMessages, memberships])

    const windowDims = useWindowDimensions()
    const numMembershipsShown = useMemo(() => {
        // we subtract -2 because of menu and account
        console.log(windowDims.width)
        return Math.floor((windowDims.width - 2 * 8) / 48) - 2
    }, [windowDims.width])

    const location = useLocation()
    const accountIsSelected = useMemo(() => location.pathname.includes("account"), [location])
    const devIsSelected = useMemo(() => location.pathname.includes("dev"), [location])

    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showJoinForm, setShowJoinForm] = useState(false)


    return (

        <Row mainAxisAlignment={"center"} crossAxisAlignment={"center"} sx={{
            width: "100%",
            height: "56px",
            // borderTop: "1px solid #E0E0E0",
            px: 1,
            bgcolor: "#ECF5FF"
        }}>
            <Row mainAxisAlignment={"spaceEvenly"} crossAxisAlignment={"center"} sx={{
                maxWidth: "600px",
                height: "56px",
                flex: 1
            }}>
                <IconButton sx={{mr: 1, color: primaryColor}} onClick={(e) => {
                    setContextMenuAnchorEl(e.currentTarget)
                    setShowMoreActions(true)
                }}>
                    <MoreHorizOutlinedIcon/>
                    {/*<Typography variant={"body1"}>*/}
                    {/*    Settings*/}
                    {/*</Typography>*/}
                </IconButton>

                <AnimatePresence>
                    {
                        memberships.slice(0, numMembershipsShown).map((m) => {
                            return (
                                <Column key={"avatar-" + m.id} mainAxisAlignment={"start"} crossAxisAlignment={"start"}
                                        sx={{
                                            position: "relative"
                                        }}>
                                    <AvatarMotion
                                        whileTap={{scale: 0.8}}
                                        key={m.id}
                                        variant={"circular"}
                                        sx={{
                                            mr: 1,
                                            bgcolor: primaryColor, //"#A9BED5", //, communityId === m.id ? primaryColor : "#A9BED5", //primaryColor,
                                            width: 30,
                                            height: 30,
                                            fontSize: 14,
                                            // ...(communityId === m.id) && {
                                            //     border: `4px solid ${primaryColor}`
                                            // }
                                        }}
                                        onClick={() => {
                                            navTo(`communities/${m.id}`)
                                        }}
                                    >
                                        {m.name.slice(0, 2)}
                                    </AvatarMotion>
                                    {
                                        communityId === m.id &&
                                        <motion.div layoutId={"communityHighlighter"} style={{
                                            borderRadius: "50%",
                                            minWidth: "8px",
                                            minHeight: "8px",
                                            position: "absolute",
                                            top: "32px",
                                            left: "11px", // 30/2 - 8/2
                                            backgroundColor: primaryColor
                                        }}/>
                                    }

                                </Column>

                            )
                        })
                    }
                </AnimatePresence>
                <IconButton sx={{color: primaryColor}} onClick={(e) => {
                    setContextMenuAnchorEl(e.currentTarget)
                    setShowCommunityList(true)
                }}>
                    <WorkspacesOutlinedIcon/>
                </IconButton>
            </Row>
            <Menu
                id="mobile-nav-more-actions"
                anchorEl={contextMenuAnchorEl}
                open={showMoreActions}
                onClose={() => {
                    setContextMenuAnchorEl(null)
                    setShowMoreActions(false)
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem selected={accountIsSelected} onClick={() => {
                    setContextMenuAnchorEl(null)
                    setShowMoreActions(false)
                    navTo("account")
                }}>
                    Account
                </MenuItem>
                {/*<MenuItem selected={devIsSelected} onClick={() => {*/}
                {/*    setContextMenuAnchorEl(null)*/}
                {/*    setShowMoreActions(false)*/}
                {/*    navTo("dev")*/}
                {/*}}>*/}
                {/*    Dev*/}
                {/*</MenuItem>*/}
            </Menu>
            <Menu
                id="mobile-nav-communities"
                anchorEl={contextMenuAnchorEl}
                open={showCommunityList}
                onClose={() => {
                    setContextMenuAnchorEl(null)
                    setShowCommunityList(false)
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => {
                    setShowCreateForm(true)
                    setContextMenuAnchorEl(null)
                    setShowCommunityList(false)
                }}>
                    Create Community
                </MenuItem>
                <MenuItem onClick={() => {
                    setShowJoinForm(true)
                    setContextMenuAnchorEl(null)
                    setShowCommunityList(false)
                }}>
                    Join Community
                </MenuItem>
                <Divider/>
                {
                    memberships.map((m) => {
                        return (
                            <MenuItem selected={m.id === communityId} id={"nav" + m.id} onClick={() => {
                                setContextMenuAnchorEl(null)
                                setShowCommunityList(false)
                                navTo(`communities/${m.id}`)
                            }}>
                                {m.name}
                            </MenuItem>
                        )
                    })
                }
            </Menu>
            <JoinCommunityModal show={showJoinForm} onClose={() => setShowJoinForm(false)}/>
            <AddCommunityModal show={showCreateForm} onClose={() => setShowCreateForm(false)}/>
        </Row>
    )

    // return (
    //     <Row mainAxisAlignment={"center"} crossAxisAlignment={"center"} sx={{
    //         width: "100%",
    //         height: "56px",
    //         borderTop: "1px solid #E0E0E0",
    //         bgcolor: primaryLightBackground
    //     }}>
    //         <Row mainAxisAlignment={"start"} crossAxisAlignment={"center"} sx={{
    //             maxWidth: "100%",
    //             height: "56px",
    //             overflow: "hidden"
    //         }}>
    //             <Row mainAxisAlignment={"start"} crossAxisAlignment={"center"} sx={{
    //                 maxWidth: "100%",
    //                 overflow: "auto"
    //             }}>
    //                 {
    //                     memberships.slice(0, numMembershipsShown).map((m) => {
    //                         return (
    //                             <Avatar sx={{mr: 1, borderRadius: "16px"}}>
    //                                 {m.name.slice(0, 2)}
    //                             </Avatar>
    //                         )
    //                     })
    //                 }
    //
    //             </Row>
    //             <Column mainAxisAlignment={"start"} crossAxisAlignment={"center"} sx={{
    //                 maxWidth: "40px"
    //             }}>
    //
    //                 {/*<Typography variant={"body1"}>*/}
    //                 {/*    More*/}
    //                 {/*</Typography>*/}
    //             </Column>
    //             {/*<div className="ion-activatable" style={{*/}
    //             {/*    minWidth: "20px",*/}
    //             {/*    minHeight: "20px",*/}
    //             {/*    maxWidth: "20px",*/}
    //             {/*    maxHeight: "20px",*/}
    //             {/*    overflow: "hidden",*/}
    //             {/*    border: "1px solid black",*/}
    //             {/*    position: "relative"*/}
    //             {/*}}>*/}
    //             {/*    <IonRippleEffect></IonRippleEffect>*/}
    //             {/*</div>*/}
    //             <Avatar className="ion-activatable ripple-parent" sx={{mr: 1, borderRadius: "16px"}}>
    //                 <ExpandMoreOutlinedIcon/>
    //             </Avatar>
    //
    //             <Divider orientation={"vertical"} sx={{height: 32, mr: 1}}/>
    //             <Avatar sx={{borderRadius: "16px", bgcolor: lightGrey}}>
    //                 <SettingsOutlinedIcon sx={{color: primaryColor}}/>
    //                 {/*<Typography variant={"body1"}>*/}
    //                 {/*    Settings*/}
    //                 {/*</Typography>*/}
    //             </Avatar>
    //
    //         </Row>
    //
    //     </Row>
    // )
}

// function PageTwo() {
//     return (
//         <>
//             <IonHeader>
//                 <IonToolbar>
//                     <IonButtons slot="start">
//                         <IonBackButton></IonBackButton>
//                     </IonButtons>
//                     <IonTitle>Page Two</IonTitle>
//                 </IonToolbar>
//             </IonHeader>
//             <IonContent class="ion-padding">
//                 <h1>Page Two</h1>
//             </IonContent>
//         </>
//     );
// }
