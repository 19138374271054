export const duration = 1.5;
export const windowHeightWhenMinimized = 50;
// export const sidebarWidth = 300;
export const neededProposalLikes = 5
export const vQAndAWebWidgetJS = "0.0.6"
export const vQAndAWebWidgetCSS = "0.0.2"
export const primaryColor = "#0063dc"
export const primaryLightBackground = "#F9FCFF"
export const primaryDarkBackground = "#001B3A"
export const secondaryColor = "#c4b682"
export const secondaryLightBackground = "#FFFDF8"
export const highlightColor = "#FFEFE3"
export const darkGrey = "#606060"
export const lightGrey = "#F2F2F2"
export const superLightGrey = "#FBFBFB" // "#F9F9F9"

export const circularProgressSize = 20

export const modalWidth = 600
