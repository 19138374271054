import {Center, Column, PaperMotion, Row} from "./motion_mui";
import {AnimatePresence, motion} from "framer-motion";
import React from "react";
import {IconButton, Typography, SxProps} from "@mui/material";
import {ClearOutlined} from "@mui/icons-material";
import useDimensions from "react-cool-dimensions";
import {modalWidth} from "../constants/constants";


export function ModalWithPresence(props: React.PropsWithChildren<{
    show: boolean,
    onCancel: () => void;
    style?: React.CSSProperties,
    paperSx?: SxProps
}>) {
    return <AnimatePresence>
        {props.show && <Modal paperSx={props.paperSx} onCancel={props.onCancel} style={props.style}>
            {props.children}
        </Modal>}
    </AnimatePresence>
}

export function ModalTitle({title, onCancel, sx}: { sx?: SxProps, title: React.ReactNode, onCancel: () => void }) {
    return (
        <Row mainAxisAlignment={"spaceBetween"}
             crossAxisAlignment={"center"}
             sx={{mb: 2, ...sx}}>
            <Typography variant={"h4"}>
                {title}
            </Typography>
            <IconButton edge={"end"} onClick={onCancel}>
                <ClearOutlined/>
            </IconButton>
        </Row>
    )
}

export function Modal(props: React.PropsWithChildren<{
    onCancel: () => void;
    style?: React.CSSProperties,
    paperSx?: SxProps
}>) {
    const dims = useDimensions()
    const transparent = "rgba(0, 0, 0, 0)"
    const shadow = "rgba(0, 0, 0, 0.5)"
    return (
        <Center
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                minWidth: "100vw",
                minHeight: "100vh",
                maxWidth: "100vw",
                maxHeight: "100vh",
                zIndex: 2000,
                overflow: "hidden",
                // padding: "20px",
                boxSizing: "border-box",
                ...props.style
            }}>
            <motion.div
                style={{
                    width: "100vw",
                    height: "100vh",
                    position: "fixed",
                    top: 0,
                    left: 0,
                }}
                onTap={props.onCancel}
                initial={{backgroundColor: transparent}}
                animate={{backgroundColor: shadow}}
                transition={{
                    ease: "easeInOut"
                }}
                exit={{backgroundColor: transparent}}>
                <div style={{position: "sticky"}}/>
            </motion.div>
            <motion.div
                style={{
                    zIndex: 1,
                    display: "flex",
                    maxHeight: "100vh",
                    maxWidth: "100vw",
                    boxSizing: "border-box",
                    overflow: "hidden"
                }}
                initial={{
                    y: 100
                }}
                // transition={{ease: "easeInOut", duration: 0.1}}
                exit={{
                    opacity: [1, 0, 0, 0, 0],
                    // scale: 0,
                    y: [0, 120, 100, 100, 100]
                }}
                animate={{
                    y: 0
                }}>
                <PaperMotion
                    sx={{
                        display: "inline-flex",

                        padding: "12px 20px 20px",
                        // maxWidth: "100% !important",
                        minWidth: `min(${modalWidth}px, 100vw - 40px)`,
                        maxWidth: modalWidth + "px",
                        width: "fit-content",
                        // maxWidth: "100%",
                        maxHeight: "100vh - 40px",
                        boxSizing: "border-box",
                        margin: "20px",
                        overflow: "auto",
                        visibility: dims.height === 0 ? "hidden" : "visible",
                        ...props.paperSx
                        // position: "relative"
                    }}>
                    <Column
                        ref={dims.observe}
                        sx={{
                            flexGrow: 1,
                            height: "fit-content",
                        }}
                        mainAxisAlignment={"start"}
                        crossAxisAlignment={"stretch"}>
                        {props.children}
                    </Column>
                </PaperMotion>
            </motion.div>
        </Center>
    )
}
