import {ModalTitle, ModalWithPresence} from "../../../../../../components/modal";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {
    Box,
    CircularProgress,
    ListItemButton,
    ListItemText, TextField
} from "@mui/material";
import {idValidator} from "shared/common/schemas";
import {joinCommunity} from "../../../../../../firebase/discunaApi";
import debounce from "lodash.debounce";
import {InvitationLinkWithInvitationId} from "../../../communities/invitationLinks/invitationLinkInterfaces";
import {InvitationLinkFirestore} from "shared";
import {useNavigate} from "react-router-dom";
import {CustomLoadingButton} from "../../../communities/accessWrappers";
import {useDispatch} from "react-redux";
import {setSelectedCommunityId} from "../../../../dashboardSlice";
import {
    InstantSearch,
    useHits,
    useSearchBox,

} from 'react-instantsearch-hooks-web';
import {isInDevMode, algoliaCustomSearchClient} from "./algolia";
import algoliaHelper from "algoliasearch-helper"
import {isUrl} from "../../../../../../utils/isUrl";
import {log} from "../../../../../../utils/log";
import {Autocomplete} from '@mui/material';
import {primaryColor, primaryLightBackground} from "../../../../../../constants/constants";
import {firestore} from "../../../../../../firebase/firebase";
import {firestoreManager} from "../../../../../../firebase/queryManager";
import {Center} from "../../../../../../components/motion_mui";

export function JoinCommunityModal({onClose, show}: {
    show: boolean,
    onClose: () => void,
}) {
    return (
        <ModalWithPresence
            show={show}
            onCancel={onClose}
        >
            <JoinCommunity onClose={onClose}/>
        </ModalWithPresence>
    )
}


// function CustomHighlight({text, query}: {text: string, query: string}) {
//
//     const
// }

export function optionIsInvitation(option: InvitationLinkWithInvitationId | any): option is InvitationLinkWithInvitationId {
    return 'invitationId' in option;
}

interface Option {
    name: string,
    shortDescription: string,
    objectID: string, // community ID (in algolia saved as objectID)
    invitationId?: string,
}

function CustomHighlight(props: {
    hit: {
        _highlightResult: Record<string, {
            value: string,
        }>
    },
    attribute: string,
}) {
    return (
        <Box
            component={"span"}
            dangerouslySetInnerHTML={{
                __html: props.hit._highlightResult[props.attribute].value
                    .replaceAll("<mark>", `<b style="text-decoration: underline">`) // style="background-color: ${primaryLightBackground}; border-radius: 8px"
                    .replaceAll("</mark>", `</b style="text-decoration: underline">`)
            }}

        />
    )
}

function CommunitySearch({handleOptionClick}: {
    handleOptionClick: (option: Option) => void
}) {

    const {query, refine, clear} = useSearchBox();

    const [isLoading, setIsLoading] = useState(false)
    const [invitationLinkOption, setInvitationLinkOption] = useState<Option | null>(null)

    const [text, setText] = useState("")

    const performSearch = useMemo(() => debounce((query: string) => {
        refine(query)
        setIsLoading(false)
    }, 0), [refine])

    const onPasteUrl = useCallback(async (url: URL) => {
        let path = url.pathname
        if (path.at(path.length - 1) === "/") {
            path = path.slice(0, path.length - 1)
        }
        const pathSplit = path.split("/")
        if (pathSplit.length !== 3) return
        const invitationId = pathSplit[2]
        console.log("invitationId", pathSplit, invitationId)
        const res = idValidator(invitationId)
        if (!res.ok) return

        // fetch invitation
        const invitationLinkRef = firestore.collection("invitationLinks").doc(invitationId)
        const invitationLinkDoc = await firestoreManager.queryDocument(invitationLinkRef)
        if (!invitationLinkDoc.exists) return
        const invitation = {
            invitationId,
            ...(invitationLinkDoc.data() as InvitationLinkFirestore)
        } as InvitationLinkWithInvitationId
        if (invitation.expiresOn && invitation.expiresOn < (new Date()).getTime()) return
        setInvitationLinkOption({
            name: invitation.details.name,
            shortDescription: invitation.details.shortDescription,
            objectID: invitation.communityId,
            invitationId
        })
    }, [])

    const handleInput = useCallback((input: string) => {
        setText(input)
        setInvitationLinkOption(null)
        if (input.length > 0) {
            if (isUrl(input)) {
                setIsLoading(true)
                onPasteUrl(new URL(input)).then(() => {
                    setIsLoading(false)
                })
            } else {
                performSearch(input)
            }
        } else {
            setIsLoading(false)
            clear()
        }
    }, [clear, performSearch, onPasteUrl])

    const hits = useHits<{
        name: string,
        shortDescription: string,
        createdOn: number
    }>()


    log("hits", hits.hits)

    hits.hits.forEach((h) => {
        log("highlightRes", h.name, h._highlightResult)
    })

    const searchRef = useRef<HTMLInputElement>(null)

    return (
        <Autocomplete
            // disablePortal={true}
            clearOnBlur={false}
            noOptionsText={"No results"}
            loading={isLoading}
            renderInput={
                (params) => (<TextField
                    {...params}
                    ref={searchRef}
                    value={text}
                    label={"Search or paste invitation link"}
                    variant={"filled"}
                    onChange={(e) => {
                        handleInput(e.currentTarget.value)
                    }}
                />)
            }
            filterOptions={(option) => option}
            options={
                text.length > 0 && !isLoading ?
                    isUrl(text) ?
                        invitationLinkOption ? [invitationLinkOption] : [] :
                        hits.hits as Option[] :
                    []
            }
            getOptionLabel={(hit) => hit.name}
            renderOption={(props, option) => {
                return (
                    <ListItemButton key={option.objectID} onClick={() => handleOptionClick(option)}>
                        {
                            "invitationId" in option ?
                                <ListItemText
                                    primary={option.name}
                                    secondary={option.shortDescription}
                                /> :
                                <ListItemText
                                    primary={<CustomHighlight
                                        hit={option as any}
                                        attribute={"name"}
                                    />}
                                    secondary={<CustomHighlight
                                        hit={option as any}
                                        attribute={"shortDescription"}
                                    />}
                                />
                        }
                    </ListItemButton>
                )
            }}
        />

    )
}

function JoinCommunity({onClose}: {
    onClose: () => void,
}) {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleOptionClick = useCallback(async (option: Option) => {
        setIsLoading(true)
        try {
            await joinCommunity(option.invitationId ?
                {
                    via: "invitation",
                    id: option.invitationId
                } : {
                    via: "search",
                    id: option.objectID
                }
            )
            dispatch(setSelectedCommunityId(option.objectID))
            navigate(`./communities/${option.objectID}/info`)
            onClose()
        } catch (e: any) {
            console.error("could not preview community invitation link", e)
            setIsLoading(false)
        }
    }, [onClose])

    return (
        <InstantSearch
            searchClient={algoliaCustomSearchClient}
            indexName={isInDevMode ? "devOpenCommunities" : "openCommunities"}
        >
            <ModalTitle title={"Join a community"} onCancel={onClose}/>
            {
                isLoading ?
                    <Center><CircularProgress/></Center> :
                    <CommunitySearch handleOptionClick={handleOptionClick}/>
            }
        </InstantSearch>
    )
}
