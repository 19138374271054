import React, { useState, useEffect } from "react";
import {primaryColor} from "../../../../constants/constants";

export function ResizeXRight({minWidth=0., maxWidth=500, onResize, isClosed=false}: {
    minWidth?: number,
    maxWidth?: number,
    onResize?: (newWidth: number) => void,
    isClosed?: boolean,
}) {
    const [isResizing, setIsResizing] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startWidth, setStartWidth] = useState(0);
    const [hovering, setHovering] = useState(false);

    const isResizingRef = React.useRef(isResizing);
    const isHoveringRef = React.useRef(hovering);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        // console.log("mouse down")
        e.preventDefault();
        const parentElement = e.currentTarget.parentElement?.parentElement?.children[0] as HTMLElement | null
        if (!parentElement) return;

        const currentWidth = parentElement.offsetWidth; // Get the current width of the parent element
        setIsResizing(true);
        isResizingRef.current = true;
        setStartX(e.clientX); // Capture the initial mouse x-position
        setStartWidth(currentWidth); // Store the initial width
        document.body.style.cursor = "ew-resize"; // Change cursor to resizing style
    };

    const handleMouseMove = (e: MouseEvent) => {
        // block default behavior
        // console.log("mouse move")
        e.preventDefault();
        if (!isResizingRef.current) return;

        const deltaX = e.clientX - startX; // Difference in mouse x-position
        const newWidth = Math.max(0, Math.min(startWidth + deltaX, maxWidth)); // Clamp the width

        // Trigger the resize event
        onResize?.(newWidth);
    };

    const handleMouseUp = (e: MouseEvent) => {
        e.preventDefault();
        if (isResizingRef.current) {
            setIsResizing(false);
            isResizingRef.current = false;
            document.body.style.cursor = "default"; // Reset cursor to default
        }
        if (!isHoveringRef.current) {
            setHovering(false);
        }
    };

    useEffect(() => {
        if (isResizing) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        }
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isResizing]);

    const handleMouseEnter = () => {
        if (!isHoveringRef.current && !isResizingRef.current) {
            setHovering(true)
        }
        isHoveringRef.current = true
    };

    const handleMouseLeave = () => {
        isHoveringRef.current = false
        if(!isResizingRef.current){
            setHovering(false);
        }
    };

    return (
        <div style={{
            position: "relative",
            width: "0",
            overflow: "visible",
        }}>

        <div
            style={{
                width: "4px",
                height: "100%",
                cursor: isClosed ? "pointer" : "ew-resize",
                position: "absolute",
                right: isClosed ? -4 : -2,
                // left: 0,
                top: 0,
                zIndex: 1001,
                // backgroundColor: "red",
                backgroundColor: hovering ? primaryColor : "transparent", // Change background based on hover state
                transition: "background-color 0.2s ease", // Smooth transition for background change

            }}
            onClick={(e) => {
                if (isClosed) {
                    onResize?.(minWidth)
                }
            }}
            onMouseDown={handleMouseDown}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        ></div>

            {(isResizing || hovering) && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1000,
                        backgroundColor: "transparent", // Can also use semi-transparent color
                        cursor: "ew-resize",
                    }}
                    // onMouseDown={handleMouseDown}
                    // onMouseEnter={handleMouseEnter}
                    // onMouseLeave={handleMouseLeave}
                />
            )}
        </div>
    );
};

